import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import Footer from "components/navigation/Footer"
import Nav from "components/navigation/Nav"

import {
  richTextBody,
  useContentfulComponent,
  useContentfulSection,
  useMeta,
} from "utils"
import MobileNav from "src/components/navigation/mobile/MobileNav"

const DocsPage = ({ data: { docs } }) => {
  const {
    docs_content: { title, components },
  } = useContentfulSection(docs.sections)

  const meta = useMeta(docs)

  const { docs_body } = useContentfulComponent(components)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />
      <Wrap>
        <VSection
          className="docs_content"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={docs_body.body}
        />
        <Footer />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  --section-padding-top: var(--sp-nav);
  /* --section-mobile-padding-bottom: 300px;
  --section-padding-bottom: 350px; */
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-background-color: none;
  background: var(--layout_gradient);

  #footer {
    display: none;
  }

  .docs_content {
    ${richTextBody};

    .title {
      margin-bottom: var(--tg-grid-gap);
    }

    strong {
      color: var(--white);
    }

    h4 {
      margin-bottom: 0;
     
    }
    h6{
     a{
        color: var(--tg-subtitle-color);  
       line-height: 1.5;
      }
    }
  }
`

export const query = graphql`
  {
    docs: contentfulPage(pageId: { eq: "docs" }) {
      ...Page
    }
  }
`

export default DocsPage
